import React, { FunctionComponent } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "../Link";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { ImageInterface, PortableText, MultiLineHeadline, Slug } from "../../types/SanityTypes";
import { ColorVariations } from "../../types/ComponentTypes";
import "./styles.scss";

export interface TeaserBlockInterface {
  _id: string;
  _rawImage: ImageInterface;
  _rawTextBody: PortableText;
  articleSingleTeaserBlockType: {
    name: string;
  };
  bgColor: {
    title: string;
    value: string;
  };
  headline?: {
    primaryText: string;
    secondaryText: string;
    tertiaryText: string;
  };
  imageAlignment: string;
  headingOverride?: boolean;
  ctaLabel: string;
  ctaLink?: {
    _type?: string;
    _id?: string;
    __typename?: string;
    slug?: {
      current: string;
    };
    headline?: MultiLineHeadline;
    _rawIntroduction?: PortableText;
    surveyBlock?: {
      submitCTA: string;
      questions: {
        question: string;
        questionType: string;
      }[];
    };
    parentSection?: {
      slug: {
        current: string;
      };
      parentSection?: Slug;
    };
  };
}

const TeaserBlock: FunctionComponent<TeaserBlockInterface> = ({
  bgColor,
  articleSingleTeaserBlockType,
  imageAlignment,
  headingOverride = false,
  _rawImage,
  _rawTextBody,
  headline,
  ctaLabel,
  ctaLink
}) => {
  const { sanityId, sanityDataset } = useSiteMetadata();
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });

  const colorVariations: ColorVariations = {
    Purple: {
      primaryGradientColor: "var(--purple-light-color)",
      secondaryGradientColor: "var(--pink-light-color)",
      gradientDegrees: "10"
    },
    Red: {
      primaryGradientColor: "var(--orange-color)",
      secondaryGradientColor: "var(--pink-medium-color)",
      gradientDegrees: "300"
    },
    Teal: {
      primaryGradientColor: "var(--get-mentored-green-color)",
      secondaryGradientColor: "var(--get-mentored-blue-color)",
      gradientDegrees: "225"
    },
    Pink: {
      primaryGradientColor: "var(--pink-bright-color)",
      secondaryGradientColor: "var(--pink-bright-color)",
      gradientDegrees: "-146"
    },
    Blue: {
      primaryGradientColor: "var(--blue-dark-color)",
      secondaryGradientColor: "var(--secondary-color)",
      gradientDegrees: "90"
    },
    Primary: {
      primaryGradientColor: "var(--primary-color)",
      secondaryGradientColor: "var(--primary-color)",
      gradientDegrees: "90"
    }
  };

  const parentAttributes = {
    colorTheme: bgColor.title as string,
    colorThemeValue: bgColor.value
  };

  const parentPath = ctaLink?.parentSection?.parentSection
    ? `${ctaLink?.parentSection?.parentSection.slug.current}/${ctaLink?.parentSection?.slug.current}`
    : ctaLink?.parentSection?.slug.current;

  const ctaUrl = ctaLink?.parentSection ? `${parentPath}/${ctaLink?.slug?.current}` : ctaLink?.slug?.current;

  const arrangementOrder = imageAlignment === "Left" ? "image-left" : "image-right";

  const tripleHeadlineText = (
    <>
      {headline?.primaryText && <span aria-hidden="true">{headline?.primaryText}</span>}
      {headline?.secondaryText}
      {headline?.tertiaryText && <span aria-hidden="true">{headline?.tertiaryText}</span>}
    </>
  );

  const tripleHeadline = (
    <div className={`triple-headline ${arrangementOrder}`}>
      <Row>
        <Col lg={{ span: 5 }} className="image-box">
          {_rawImage && (
            <picture
              className="placeholder"
              style={
                _rawImage.asset.metadata
                  ? {
                      paddingTop: `calc(32% / ${_rawImage.asset.metadata.dimensions.aspectRatio})`,
                      paddingBottom: `calc(28% / ${_rawImage.asset.metadata.dimensions.aspectRatio})`,
                      background: `url(${_rawImage.asset.metadata.lqip})`,
                      backgroundSize: "cover"
                    }
                  : undefined
              }
            >
              <source
                media={"(min-width: 1200px)"}
                srcSet={
                  urlBuilder.image(_rawImage).auto("format").quality(80).width(700).height(900).url() || undefined
                }
              />
              <source
                media={"(min-width: 768px)"}
                srcSet={
                  urlBuilder.image(_rawImage).auto("format").quality(80).width(500).height(700).url() || undefined
                }
              />
              <img
                src={urlBuilder.image(_rawImage).auto("format").quality(80).width(500).height(700).url() || undefined}
                alt={_rawImage.alt}
                className="img-fluid img-center"
                loading={"lazy"}
              />
            </picture>
          )}
        </Col>
        <Col lg={{ span: 1, order: 2 }} aria-hidden="true" />
        <Col lg={{ span: 6 }} className="text-box">
          {headingOverride ? (
            <h1 className="subhead" style={{ color: parentAttributes.colorThemeValue }}>
              {tripleHeadlineText}
            </h1>
          ) : (
            <h2 className="subhead" style={{ color: parentAttributes.colorThemeValue }}>
              {tripleHeadlineText}
            </h2>
          )}
          {_rawTextBody && <BlockContent blocks={_rawTextBody} />}
        </Col>
      </Row>
    </div>
  );

  const boxColorBorder = (
    <div className={`box-border-color ${arrangementOrder}`}>
      <style>
        {`
          .bordered-box:after {
            border: 3px solid ${parentAttributes.colorThemeValue};
          }
          @media (max-width: 991px) {
            .bordered-box {
              border: 3px solid ${parentAttributes.colorThemeValue};
            }
          }
        `}
      </style>
      <Row>
        <Col lg={8} className="image-box">
          {_rawImage && (
            <picture
              className="placeholder"
              style={
                _rawImage.asset.metadata
                  ? {
                      paddingTop: "30%",
                      paddingBottom: "30%",
                      background: `url(${_rawImage.asset.metadata.lqip})`,
                      backgroundSize: "cover"
                    }
                  : undefined
              }
            >
              <source
                media={"(min-width: 1200px)"}
                srcSet={
                  urlBuilder.image(_rawImage).auto("format").quality(80).width(1000).height(660).url() || undefined
                }
              />
              <source
                media={"(min-width: 700px)"}
                srcSet={
                  urlBuilder.image(_rawImage).auto("format").quality(80).width(960).height(634).url() || undefined
                }
              />
              <img
                src={urlBuilder.image(_rawImage).auto("format").quality(80).width(700).height(462).url() || undefined}
                alt={_rawImage.alt}
                className="img-fluid"
                loading={"lazy"}
              />
            </picture>
          )}
        </Col>
        <Col lg={{ span: 4 }} sm={{ span: 10 }} className="text-box">
          <div className="bordered-box">
            <h2 className="subhead" style={{ color: parentAttributes.colorThemeValue }}>
              {tripleHeadlineText}
            </h2>
            {_rawTextBody && <BlockContent blocks={_rawTextBody} />}
            {ctaLabel && ctaLink && (
              <Link to={ctaUrl} className={`btn-primary`}>
                {ctaLabel}
              </Link>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );

  const boxGradient = `linear-gradient(${colorVariations[parentAttributes.colorTheme]?.primaryGradientColor}, ${
    colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor
  }),
  linear-gradient(${colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor}, ${
    colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor
  })`;

  const boxPopOut = (
    <>
      <style>
        {`
          .box-pop-out-background {
            background-image: linear-gradient(${colorVariations[parentAttributes.colorTheme]?.primaryGradientColor}, ${
          colorVariations[parentAttributes.colorTheme]?.primaryGradientColor
        });
          }
          @media (min-width: 992px) {
            .box-pop-out-background {
              background-image: linear-gradient(${colorVariations[parentAttributes.colorTheme]?.primaryGradientColor},${
          colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor
        }),
              linear-gradient(${colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor}, $${
          colorVariations[parentAttributes.colorTheme]?.secondaryGradientColor
        });
            }
          }
        `}
      </style>
      <Row
        noGutters
        className={`box-pop-out align-items-stretch ${arrangementOrder}`}
        style={{ backgroundImage: boxGradient }}
      >
        <Col lg={6} className="image-box" style={{ borderColor: parentAttributes.colorThemeValue }}>
          {_rawImage && (
            <picture
              className="placeholder"
              style={
                _rawImage.asset.metadata
                  ? {
                      paddingTop: `calc(45% / ${_rawImage.asset.metadata.dimensions.aspectRatio})`,
                      paddingBottom: `calc(45% / ${_rawImage.asset.metadata.dimensions.aspectRatio})`,
                      background: `url(${_rawImage.asset.metadata.lqip})`,
                      backgroundSize: "cover"
                    }
                  : undefined
              }
            >
              <source
                media={"(min-width: 1200px)"}
                srcSet={
                  urlBuilder.image(_rawImage).auto("format").quality(80).width(1000).height(800).url() || undefined
                }
              />
              <source
                media={"(min-width: 768px)"}
                srcSet={
                  urlBuilder.image(_rawImage).auto("format").quality(80).width(700).height(600).url() || undefined
                }
              />
              <img
                src={urlBuilder.image(_rawImage).auto("format").quality(80).width(500).height(400).url() || undefined}
                alt={_rawImage.alt}
                className="img-fluid img-center"
                loading={"lazy"}
              />
            </picture>
          )}
        </Col>
        <Col lg={6} className="text-box" style={{ borderColor: parentAttributes.colorThemeValue }}>
          <div className="text-box-2">
            <h2 className="subhead" style={{ color: parentAttributes.colorThemeValue }}>
              {tripleHeadlineText}
            </h2>
            {_rawTextBody && <BlockContent blocks={_rawTextBody} />}
          </div>
        </Col>
      </Row>
    </>
  );

  const renderDoubleTeaser = () => {
    if (articleSingleTeaserBlockType.name === "Triple Headline") return tripleHeadline;
    if (articleSingleTeaserBlockType.name === "Box Colour Border") return boxColorBorder;
    if (articleSingleTeaserBlockType.name === "Box Pop Out") return boxPopOut;
  };

  return (
    <section data-testid="teaser-block" className="teaser-block teaser-block--experimental">
      <Container fluid>{renderDoubleTeaser()}</Container>
    </section>
  );
};

export default TeaserBlock;
